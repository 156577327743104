import XyzCard from './XyzCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import XyzListUtil,{IXyzListDataObj} from './xyzListUtil';
import language from './xyzLanguage'
export default defineComponent ({
    name: 'XyzList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IXyzListDataObj=reactive<IXyzListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: XyzCard,
                cardFrom:'XyzList',
                modelMethod: utils.OrderProviderApi.buildUrl('/xyz/pageData')
            },
            otherParams:{
                customerData:[],//客户下拉数据
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XyzListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'制单'},{value:1,label:'流程中'},{value:2,label:'驳回'},{value:3,label:'结束'}];
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});